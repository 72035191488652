@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-BoldItalic.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-BookItalic.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-Book.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-Book.woff') format('woff');
    font-weight: 450;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-Black.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-Medium.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-Medium.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-Bold.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-Black.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-BoldItalic.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-BlackItalic.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-MediumItalic.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-Bold.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-BlackItalic.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-MediumItalic.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-Book.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-Book.woff') format('woff');
    font-weight: 450;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../../../font/CircularStd/CircularStd-BookItalic.woff2') format('woff2'),
        url('../../../font/CircularStd/CircularStd-BookItalic.woff') format('woff');
    font-weight: 450;
    font-style: italic;
    font-display: swap;
}

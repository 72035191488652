// -- Import termina
@import 'CircularStd';
$main-font : 'CircularStd';

// -- Font weights
$thin : 100;
$extra-light : 200;
$light : 300;
$regular : 400;
$book: 450;
$medium : 500;
$semiBold : 600;
$bold : 700;
$heavy : 800;
$black : 900;


* {
    font-family: $main-font;
}

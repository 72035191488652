.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    width: 1340px;

    @include breakpoint($xs) {
        padding: 0 30px;
    }

    &--base {
        height: 100%;
    }

    &--small {
        max-width: 600px;
    }
}

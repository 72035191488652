.form-page {
    padding-top: 25px;
    padding-bottom: 150px;
    &__header {
        padding-bottom: 25px;
        display: flex;
        justify-content: space-between;

        input {
            height: 50px;
            width: 250px;
            border: 1px solid #E4E4E4;
            border-radius: 4px;
            padding: 0 20px;
        }
    }

    
}

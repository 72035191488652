.popup {
    position: fixed;
    top: 0;
    z-index: 999;
    background: rgba(0, 0, 0, .2);
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    &__window {
        width: calc(100%);
        max-height: 100vh;
        overflow-y: auto;
        background: white;
        border-radius: 6px;


        max-width: 600px;


        &__header {
            padding: 20px;
            border-bottom: 1px solid #f1f1f1;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__title {
                font-weight: $medium;
                font-size: 16px;
                line-height: 21px;
            }

            &__dismiss {
                &__icon {
                    cursor: pointer;
                }
            }
        }

        &__content {
            padding: 20px;

            &__table {
                border-spacing: 0;
                border-collapse: collapse;
                tr {
                    td {
                        padding: 8px;
                        border-top: 1px solid #f1f1f1;
                        vertical-align: top;

                        &:first-of-type {
                            white-space: nowrap;
                        }
                    }
                    &:nth-of-type(even) {
                        td {
                            background: #f9f9f9;
                        }

                    }

                    &:first-of-type {
                        td {
                            border-top: 0;
                        }
                    }
                }
            }
        }

        &__footer {
            display: flex;
            justify-content: flex-end;
            padding: 20px;
            border-top: 1px solid #f1f1f1;

            &--dual {
                justify-content: space-between;
            }

        }
    }




}

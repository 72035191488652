.header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    border-bottom: 1px solid #ECECEC;

    background: white;

    &__top {
        height: 30px;
        background: black;
    }

    &__bottom {
        height: 55px;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
    .container {
        height: 100%;
    }
    &__inner {
        height: 100%;

        &__grid {
            height: 100%;


            display: grid;
            grid-template-columns: 1fr 300px 1fr;
            &__menu, &__actions{
                display: flex;
                align-items: center;
                nav {
                    ul {
                        list-style: none;
                        display: flex;
                        gap: 25px;
                        li {
                            a {
                                font-size: 15px;
                                color: black;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            &__actions {
                justify-content: flex-end;
            }
            &__logo {
                width: 100%;
                height: 100%;
                &__wrapper {
                    justify-content: center;
                    width: 100%;
                    display: flex;
                    height: 100%;
                    align-items: center;
                    background: white;

                    a {
                        font-size: 20px;
                        font-weight: $medium;
                        color: black;
                        text-decoration: none;
                    }

                }
            }
        }

    }
}

.list-overview {
    padding-top: 25px;
    padding-bottom: 150px;
    &__header {
        padding-bottom: 25px;
        display: flex;
        justify-content: space-between;

        input {
            height: 50px;
            width: 250px;
            border: 1px solid #E4E4E4;
            border-radius: 4px;
            padding: 0 20px;
        }
    }

    &__table {

        background: white;

        .rt-thead {
            .rt-tr {
                .rt-th {
                    padding: 8px;
                    text-align: left;
                }
            }

        }
        .rt-tr-group {
            &:nth-of-type(even) {
                background: #f9f9f9;
            }

            .rt-td {
                padding: 8px;

                .table-actions-column {
                    text-align: center;

                    a {
                        color: $col-1;
                    }
                }
            }

            .popup-toggle {
                text-decoration: underline;
                cursor: pointer;
                color: $col-1;
            }
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    &__row {
        display: flex;
        flex-direction: column;
        gap: 5px;
        label {
            font-weight: $medium;
        }
        input {
            width: 100%;
            height: 40px;

            border: 1px solid #E4E4E4;
            border-radius: 4px;
            padding: 0 20px;
        }

        .editor {
            background: white;

            .DraftEditor-editorContainer{
                min-height: 200px;
                padding: 0 25px;
            }
        }

        &--submit {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
        }
    }
}

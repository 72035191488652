@import '../../../../node_modules/breakpoint-sass/stylesheets/breakpoint';
// $
@charset "UTF-8";

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins',
  'abstracts/animations';

// 2. Vendors
@import
  'vendors/normalize';

// 3. Base stuff
@import
    'base/typography',
    'base/reset';

// 4. Layout-related sections
@import
  'layout/header',
  'layout/footer',
  'layout/main';

// 5. Components
@import
  'components/container',
  'components/page-header',
  'components/popup',
  'components/form',
  'components/auth',
  'components/loader',
  'components/button';

// 6. Page-specific styles
@import
  'pages/home',
  'pages/list',
  'pages/form-page';

// 7. Themes
@import
  'themes/default';


  body {
      background: $main-background;
  }


.authentication {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

html, body, #root {
    height: 100%;
}

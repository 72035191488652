.button {
    height: 50px;
    border-radius: 25px;
    border: 0;
    -webkit-appearance: none;
    text-decoration: none;
    font-size: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 15px;
    width: auto;
    font-weight: $medium;
    cursor: pointer;
    @include breakpoint($md) {
        gap: 30px;
        padding: 0 40px;
    }

    &--black {
        background: black;
        color: white;

    }

    &--red {
        background: $col-2;
        color: white;
    }

    &--login {
        text-align: center;
        justify-content: center;
        gap: 5px;

        color: black;

        span {
            font-weight: $semiBold;
            text-decoration: underline;
        }
    }

    &--submit {
        background: black;
        color: white;

        justify-content: center;
    }
}

main {
    height: 100%;

    padding-top: $header-height;
    .main {
        &__inner {
            &__grid {

            }
        }
    }
}

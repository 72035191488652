.auth {
    width: 100%;

    @include breakpoint($xs) {
        max-width: 380px;
        margin: 0 auto;
    }

    &__title {
        font-weight: $medium;
        font-size: 32px;
        line-height: 32px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 10px;
        width: 226px;

    }

    &__text {
        font-size: 15px;
        font-weight: $book;
        line-height: 22px;
        width: 264px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 30px;
    }

    &__buttons {
        &__list {
            display: flex;
            flex-direction: column;
            gap: 10px;
            list-style: none;
        }
    }

    &__login-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 100px;

        @include breakpoint($xs) {
            margin-bottom: 60px;
        }

        input {
            background: #FFFFFF;
            border: 0;
            height: 50px;
            padding: 0 20px;
            border-radius: 2px;

        }

        button {
            margin-top: 10px;
        }

        a {
            margin-top: 10px;
            text-align: center;
            color: black;
            font-size: 15px;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 18px;
        &__row {
            display: flex;
            flex-direction: column;
            gap: 7px;

            label {
                font-size: 12px;
                line-height: 15px;
                letter-spacing: -0.2px;
            }

            input {
                background: #F7F7F7;
                border: 0;
                height: 45px;
                padding: 0 15px;
                &:focus, &:active {
                    border: 2px solid $col-1;
                }
            }
        }
    }
}
